import { template as template_61185f2f5b9e49f8b13d5ec17f4b9be6 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_61185f2f5b9e49f8b13d5ec17f4b9be6(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
