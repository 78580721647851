import { template as template_680c4e7e91a146d6aa1e8011807559b0 } from "@ember/template-compiler";
const FKControlMenuContainer = template_680c4e7e91a146d6aa1e8011807559b0(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
