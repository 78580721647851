import { template as template_9bbe3e91698b4718b987dc05a02d8dd3 } from "@ember/template-compiler";
const WelcomeHeader = template_9bbe3e91698b4718b987dc05a02d8dd3(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
