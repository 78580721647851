import { template as template_0c42a143d85e4d0083ba031662fb498b } from "@ember/template-compiler";
const SidebarSectionMessage = template_0c42a143d85e4d0083ba031662fb498b(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
